main {
  min-height: 80vh;
}

@media (max-width: 767px) {
  .carousel-image {
    max-height: 200px;
  }
}

.rating span {
  margin: 0.1rem;
}

.rating svg {
  color: #f8e825;
}

.rating-text {
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 0.5rem;
}

.btn-block {
  margin-bottom: 10px;
}

.property-title {
  height: 2.5em; /* Set a fixed height */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis for long text */
  white-space: nowrap; /* Prevent wrapping */
}

table td,
table th {
  text-align: center;
}

.review {
  margin-top: 30px;
  padding: 15px;
}

.review h2 {
  font-size: 24px;
  background: #f4f4f4;
  padding: 10px;
  border: 1px solid #ddd;
}

.review button {
  margin-top: 10px;
}

.carousel-caption {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.carousel-image {
  max-height: 300px;
  object-fit: cover;
}

.property-description {
  max-height: 100px; /* Set a maximum height for the description */
  overflow: hidden;  /* Hide overflowing content */
  text-overflow: ellipsis; /* Show an ellipsis (...) for overflowed text */
  white-space: nowrap; /* Prevent text wrapping */
}

.carousel-item-container {
  position: relative;
}

.image-container {
  position: relative;
}

.watermark {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 5px;
  z-index: 10;
}
