body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .landing-page {
    text-align: center;
  }
  
  .hero-section {
    background: url('../assets/WhatsApp\ Image\ 2024-06-12\ at\ 9.32.44\ AM-2.jpeg') no-repeat center center/cover;
    color: white;
    padding: 100px 20px;
  }
  
  .hero-buttons .btn-primary,
  .hero-buttons .btn-secondary {
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
  }
  
  .search-bar {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .featured-properties .properties-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin: 20px;
  }
  
  .property-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .footer {
    background: #333;
    color: white;
    padding: 20px;
  }
  